import Technology from "./Technology";
import Laravel from '../../../images/logo/technologies/laravel.png'
import Flutter from '../../../images/logo/technologies/flutter.png'
import Firebase from '../../../images/logo/technologies/firebase.png'
import ReactNative from '../../../images/logo/technologies/react-native.png'
import Kubernetes from '../../../images/logo/technologies/kubernetes.png'

interface ITechnologies {
  [key: string]: Technology
}

const Technologies: ITechnologies = {
  LARAVEL: {
    logo: Laravel,
    name: 'Laravel'
  },
  FLUTTER: {
    logo: Flutter,
    name: 'Flutter'
  },
  FIREBASE: {
    logo: Firebase,
    name: 'Firebase'
  },
  REACT_NATIVE: {
    logo: ReactNative,
    name: 'React Native'
  },
  KUBERNETES: {
    logo: Kubernetes,
    name: 'Kubernetes'
  }
};

export default Technologies as ITechnologies;