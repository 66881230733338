import Technology from "./Technology";
import WorkSection from "./WorkSection";

interface Props {
  slug?: string;
  title: string;
  description: string;

  logo: any;
  background: any;
  coverImage: any;

  scopes?: string[];
  deliverables?: string[];
  technologies?: Technology[];
  sections?: WorkSection[];
}

class OurWork {
  slug: string;
  title: string;
  description: string;

  logo: any;
  background: any;
  coverImage: any;

  scopes: string[];
  deliverables: string[];
  technologies: Technology[];
  sections: WorkSection[];

  constructor(props: Props) {
    const { slug, title, description } = props;
    const { logo, background, coverImage } = props;
    const { scopes, deliverables, technologies, sections } = props;

    this.slug = slug ?? '';
    this.title = title;
    this.description = description;

    this.logo = logo;
    this.background = background;
    this.coverImage = coverImage;

    this.scopes = scopes ?? [];
    this.deliverables = deliverables ?? [];
    this.technologies = technologies ?? [];
    this.sections = sections ?? [];
  }

  get hasDetailScreen() {
    return this.scopes.length > 0;
  }
}

export default OurWork;