import OurWork from "./OurWork";
import ApituLogo from "../../../images/logo/apitu.png";
import a7ProBg from "../../../images/illustrations/our-work-a7pro.png";
import PlnLogo from "../../../images/logo/pln.png";
import asiikBg from "../../../images/illustrations/our-work-asiik.png";
import CawangLogo from "../../../images/logo/cawang.png";
import cariUangBg from "../../../images/illustrations/our-work-cari-uang.png";
import dummyBackground from "../../../images/dummy.png";
import Technologies from "./Technologies";
import a7ProCover from '../../../images/illustrations/our_works/cover_a7pro.png'

import a7ProSection1_1 from '../../../images/illustrations/our_works/a7pro/section_1_1.png'
import a7ProSection2_1 from '../../../images/illustrations/our_works/a7pro/section_2_1.png'
import a7ProSection3_1 from '../../../images/illustrations/our_works/a7pro/section_3_1.png'

import asiikCover from '../../../images/illustrations/our_works/asiik/cover_asiik.png'
import asiikSection1_1 from '../../../images/illustrations/our_works/asiik/section_1_1.png'
import asiikSection2_1 from '../../../images/illustrations/our_works/asiik/section_2_1.png'
import asiikSection3_1 from '../../../images/illustrations/our_works/asiik/section_3_1.png'

const OurWorks: Record<string, OurWork> = {
  A7PRO: new OurWork({
    slug: 'a7pro',
    title: 'A7Pro',
    description: 'An app that consists of AC maintaining services such as cleaning and repairing. Maintaining services integrated with AC Marketplace to fulfill customer needs.',
    logo: ApituLogo,
    background: a7ProBg,
    coverImage: a7ProCover,
    scopes: ['Design', 'Implementation', 'Support'],
    deliverables: ['Web Organization', 'Customer App', 'Technician App'],
    technologies: [Technologies.LARAVEL, Technologies.FLUTTER, Technologies.FIREBASE],
    sections: [
      {
        title: 'Air Conditioner Services as easy as one click',
        description: 'Available for wide range of Air Conditioner type. A7Pro is a go-to app for AC services.',
        photos: [a7ProSection1_1]
      },
      {
        title: 'Order AC Service is simple and fast',
        description: 'you can order service for every AC type and select your service date and location.',
        photos: [a7ProSection2_1]
      },
      {
        title: 'Detailed task and pricing',
        description: 'The technician inspects and begins the service process. when the service is done, the user can pay the price listed on the app. ',
        photos: [a7ProSection3_1]
      }
    ]
  }),
  ASIIK: new OurWork({
    slug: 'asiik',
    title: 'ASIIK',
    description: 'Inspection and maintenance activity app to control operational risk entirely in real-time, measurable, accurate, and effective.',
    logo: PlnLogo,
    background: asiikBg,
    coverImage: asiikCover,
    scopes: ['Design', 'Implementation', 'Support'],
    deliverables: ['Web Organization', 'Operation Supporting App'],
    technologies: [Technologies.LARAVEL, Technologies.REACT_NATIVE, Technologies.KUBERNETES],
    sections: [
      {
        title: 'Manage PLN Assets and  Organization',
        description: 'Manage user, dynamic organization structure, dynamic user role and position, inspection and maintenance form, company assets, and many more.',
        photos: [asiikSection1_1]
      },
      {
        title: 'Inspect and Maintain Asset Performance',
        description: 'Perform maintenance flow from creating work permission, fill inspection form, discover malfunction assets, fix and replace asset components, and send approval to the supervisor.',
        photos: [asiikSection2_1]
      },
      {
        title: 'Dashboard  and Reporting',
        description: 'Providing relevant information based on collective data to describe the current asset condition. ',
        photos: [asiikSection3_1]
      }
    ]
  }),
  CARI_UANG: new OurWork({
    slug: 'cari-uang',
    title: 'Cari Uang',
    description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat',
    logo: CawangLogo,
    background: cariUangBg,
    coverImage: ''
  }),
  SMAPP: new OurWork({
    slug: 'smapp',
    title: 'SMAPP',
    description: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat',
    logo: PlnLogo,
    background: dummyBackground,
    coverImage: ''
  })
};

export default OurWorks;
